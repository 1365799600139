import React, { useEffect } from 'react';
import { Grid, VStack, HStack, Image, Box, Link, Heading, Text, useBreakpointValue } from '@chakra-ui/react';
import HeaderBanner from '../components/HeaderBanner';
import '../projectseffects.css';

const Projects = () => {
  useEffect(() => {
    document.title = 'Projects - Jake Harris Codes';
  }, []);

  const isMobile = useBreakpointValue({ base: true, lg: false });

  return (
    <>
      <HeaderBanner headerText="Projects" bodyText="A list of projects I've been involved in, and my skills. Click on the image for the link." />
      <Grid templateColumns="1fr" maxW="1100px" mx="auto" mt="30px" p={{ base: '4', lg: '0' }}>
        {isMobile ? (
          <VStack spacing="10">
            <Link href="https://github.com/JakeHarrisCodes/ASPBookingsSite/tree/main" isExternal>
              <Box className="project-link" borderRadius="xl">
                <Box className="image-box">
                  <Image
                    maxWidth="100%"
                    objectFit="cover"
                    src="project1.png"
                    alt="Page from Hotel Booking Website Image"
                  />
                </Box>
                <Box className="content">
                  <Heading>Hotel Booking Website</Heading>
                  <Text>A mockup website for a fully functioning apartment booking website. I created this ASP.NET website for my university course, and got an 100% grade for it. Open source!</Text>
                </Box>
              </Box>
            </Link>
            <Link href="https://web.archive.org/web/20230222062953/http://cbdcarcare.com.au/" isExternal>
              <Box className="project-link" borderRadius="xl">
                <Box className="image-box">
                  <Image
                    maxWidth="100%"
                    objectFit="cover"
                    src="project2.png"
                    alt="Home Page from CBD Car Care Website Image"
                  />
                </Box>
                <Box className="content">
                  <Heading>CBD Car Care Website</Heading>
                  <Text>Created a complete website for my father's car wash business. It was my first public project that I was involved in when I was still a teenager. Made it with Bootstrap and Vue.js. Website is now defunct but the web archive still exists.</Text>
                </Box>
              </Box>
            </Link>
            <Link href="https://www.youtube.com/channel/UChnmpMyV8-3G6v7myb-EFaQ" isExternal>
              <Box className="project-link" borderRadius="xl" mb="6">
                <Box className="image-box">
                  <Image
                    maxWidth="100%"
                    objectFit="cover"
                    objectPosition="75% 90% !important"
                    src="project3.png"
                    alt="My YouTube Channel Image"
                  />
                </Box>
                <Box className="content">
                  <Heading>YouTube Channel</Heading>
                  <Text>My YouTube channel showcases a few videos for tutorials with technologies that I am proficient with. It's mostly not in use now but the account will stay active in order to showcase my older content.</Text>
                </Box>
              </Box>
            </Link>
          </VStack>
        ) : (
          <HStack spacing="4" direction="row" gap="10">
            <Link href="https://github.com/JakeHarrisCodes/ASPBookingsSite/tree/main" isExternal>
              <Box className="project-link" borderRadius="xl">
                <Box className="image-box">
                  <Image
                    maxWidth="100%"
                    objectFit="cover"
                    src="project1.png"
                    alt="Page from Hotel Booking Website Image"
                  />
                </Box>
                <Box className="content">
                  <Heading>Hotel Booking Website</Heading>
                  <Text>A mockup website for a fully functioning apartment booking website. I created this ASP.NET website for my university course, and got an 100% grade for it. Open source!</Text>
                </Box>
              </Box>
            </Link>
            <Link href="https://web.archive.org/web/20230222062953/http://cbdcarcare.com.au/" isExternal>
              <Box className="project-link" borderRadius="xl">
                <Box className="image-box">
                  <Image
                    maxWidth="100%"
                    objectFit="cover"
                    src="project2.png"
                    alt="Home Page from CBD Car Care Website Image"
                  />
                </Box>
                <Box className="content">
                  <Heading>CBD Car Care Website</Heading>
                  <Text>Created a complete website for my father's car wash business. It was my first public project that I was involved in when I was still a teenager. Made it with Bootstrap and Vue.js. Website is now defunct but the web archive still exists.</Text>
                </Box>
              </Box>
            </Link>
            <Link href="https://www.youtube.com/channel/UChnmpMyV8-3G6v7myb-EFaQ" isExternal>
              <Box className="project-link" borderRadius="xl">
                <Box className="image-box">
                  <Image
                    maxWidth="100%"
                    objectFit="cover"
                    objectPosition="75% 90% !important"
                    src="project3.png"
                    alt="My YouTube Channel Image"
                  />
                </Box>
                <Box className="content">
                  <Heading>YouTube Channel</Heading>
                  <Text>My YouTube channel showcases a few videos for tutorials with technologies that I am proficient with. It's mostly not in use now but the account will stay active in order to showcase my older content.</Text>
                </Box>
              </Box>
            </Link>
          </HStack>
        )}
      </Grid>
    </>
  );
};

export default Projects;
