import React, { useLayoutEffect, useState } from 'react';
import {
  Box,
  Grid,
  Flex,
  Link,
  Heading,
  useBreakpointValue,
  Spacer,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from '@chakra-ui/react';
import { Link as ReactRouterLink, useLocation } from 'react-router-dom';
import { FaCode, FaBars } from 'react-icons/fa';

const Navbar = () => {
  const location = useLocation();

  const isMd = useBreakpointValue({ base: false, md: true });
  const [showDropdown, setShowDropdown] = useState(false);
  const [dropdownVisible, setDropdownVisible] = useState(false);

  useLayoutEffect(() => {
    setShowDropdown(!isMd);
  }, [isMd]);

  useLayoutEffect(() => {
    setDropdownVisible(true);
  }, []);

  return (
    <Box bg="rgba(0, 0, 0, 0.8)" p="4" w="100%" zIndex="100" h="75px">
      <Grid templateColumns="1fr 1fr" maxW="1100px" mx="auto">
        <Flex alignItems="center">
          <Box>
            <Heading as="h2" fontSize="3xl" color="white">
              <Link as={ReactRouterLink} to="/" color="white" _hover={{ textDecoration: 'none' }}>
                <FaCode style={{ marginRight: '0.5rem' }} />
              </Link>
            </Heading>
          </Box>
          {isMd && (
            <Box>
              <Heading as="h2" fontSize="3xl" color="white">
                <Link as={ReactRouterLink} to="/" color="white" _hover={{ textDecoration: 'none' }}>
                  Jake Harris Codes
                </Link>
              </Heading>
            </Box>
          )}
        </Flex>
        <Flex alignItems="center" justifyContent="flex-end">
          {showDropdown && (
            <>
              <Spacer />
              <Menu autoSelect={false}>
                <MenuButton
                  as={IconButton}
                  icon={<FaBars />}
                  variant="outline"
                  color="white"
                  border="none !important"
                  display={isMd ? 'none' : 'inline-flex'}
                />
                <MenuList>
                  {dropdownVisible && (
                    <>
                      <MenuItem
                        as={ReactRouterLink}
                        to="/"
                        color="white"
                        fontWeight="bold"
                        position="relative"
                        bgColor={location.pathname === '/' ? "#242c3a" : undefined }
                      >
                        Home
                      </MenuItem>
                      <MenuItem
                        as={ReactRouterLink}
                        to="/contact"
                        color="white"
                        fontWeight="bold"
                        position="relative"
                        bgColor={location.pathname === '/contact' ? "#242c3a" : undefined }
                      >
                        Contact
                      </MenuItem>
                      <MenuItem
                        as={ReactRouterLink}
                        to="/projects"
                        color="white"
                        fontWeight="bold"
                        position="relative"
                        bgColor={location.pathname === '/projects' ? "#242c3a" : undefined }
                      >
                        Projects
                      </MenuItem>
                    </>
                  )}
                </MenuList>
              </Menu>
            </>
          )}
          {isMd && (
            <>
              <Link
                as={ReactRouterLink}
                to="/"
                color="white"
                ml="4"
                fontWeight="bold"
                _hover={{ color: 'gray.200', bg: 'rgba(137,137,137, 0.1)', rounded: 'md' }}
                p="2"
                position="relative"
              >
                Home
                {location.pathname === '/' && (
                  <Box
                    position="absolute"
                    bottom="-1.5"
                    left="50%"
                    transform="translateX(-50%)"
                    borderBottom="2px solid #fff"
                    width="85%"
                    borderRadius="6px"
                  ></Box>
                )}
              </Link>
              <Link
                as={ReactRouterLink}
                to="/contact"
                color="white"
                ml="4"
                fontWeight="bold"
                _hover={{ color: 'gray.200', bg: 'rgba(137,137,137, 0.1)', rounded: 'md' }}
                p="2"
                position="relative"
              >
                Contact
                {location.pathname === '/contact' && (
                  <Box
                    position="absolute"
                    bottom="-1.5"
                    left="50%"
                    transform="translateX(-50%)"
                    borderBottom="2px solid #fff"
                    width="85%"
                    borderRadius="6px"
                  ></Box>
                )}
              </Link>
              <Link
                as={ReactRouterLink}
                to="/projects"
                color="white"
                ml="4"
                fontWeight="bold"
                _hover={{ color: 'gray.200', bg: 'rgba(137,137,137, 0.1)', rounded: 'md' }}
                p="2"
                position="relative"
              >
                Projects
                {location.pathname === '/projects' && (
                  <Box
                    position="absolute"
                    bottom="-1.5"
                    left="50%"
                    transform="translateX(-50%)"
                    borderBottom="2px solid #fff"
                    width="85%"
                    borderRadius="6px"
                  ></Box>
                )}
              </Link>
            </>
          )}
        </Flex>
      </Grid>
    </Box>
  );
};

export default Navbar;