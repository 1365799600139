// src/App.js

import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ChakraProvider, CSSReset } from '@chakra-ui/react';
import Layout from './Layout';
import Index from './pages/Index';
import Contact from './pages/Contact';
import Projects from './pages/Projects';
import PageNotFound from './pages/PageNotFound';

const App = () => {
  return (
    <ChakraProvider>
      <CSSReset />
      <Router>
        <Routes>
          <Route path="/" element={<Layout><Index /></Layout>} />
          <Route path="/contact" element={<Layout><Contact /></Layout>} />
          <Route path="/projects" element={<Layout><Projects /></Layout>} />
          <Route path="*" element={<Layout><PageNotFound /></Layout>} />
        </Routes>
      </Router>
    </ChakraProvider>
  );
};

export default App;
