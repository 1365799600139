// components/SocialIcon.js
import React, { useState } from "react";
import { Box, Text } from "@chakra-ui/react";

const SocialIcon = ({ profileLink, icon, col }) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleHover = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <Box
      as="a"
      href={profileLink}
      target="_blank"
      rel="noopener noreferrer"
      display="inline-block"
      position="relative"
    >
      <Text
        fontSize="4xl"
        _hover={{
          color: `${col}`,
        }}
      >
        {React.cloneElement(icon, {
          style: {
            color: isHovered ? `${col}` : "#FFFFFF",
            transform: `scale(${isHovered ? 1.3 : 1})`,
            transition: "transform 0.3s ease-in-out",
          },
          onMouseEnter: handleHover,
          onMouseLeave: handleMouseLeave,
        })}
      </Text>
    </Box>
  );
};

export default SocialIcon;
