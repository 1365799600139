import React, { useState, useEffect } from 'react';
import { Box, Button, Grid, Text, FormControl, FormLabel, FormErrorMessage, Input, ThemeProvider, Textarea, extendTheme, useToast } from '@chakra-ui/react';
import { useFormik } from 'formik';
import { IoIosCheckmark } from "react-icons/io";
import HeaderBanner from '../components/HeaderBanner';
import * as Yup from 'yup';
import ReCAPTCHA from "react-google-recaptcha";
import '../recaptcha-fix.css';

const activeLabelStyles = {
  transform: "scale(0.85) translateY(-24px)"
};

const theme = extendTheme({
  components: {
    Form: {
      variants: {
        floating: {
          container: {
            _focusWithin: {
              label: {
                ...activeLabelStyles
              }
            },
            "input:not(:placeholder-shown) + label, .chakra-select__wrapper + label, textarea:not(:placeholder-shown) ~ label": {
              ...activeLabelStyles
            },
            label: {
              top: 0,
              left: 0,
              zIndex: 2,
              position: "absolute",
              backgroundColor: "#11151D",
              pointerEvents: "none",
              mx: 3,
              px: 1,
              my: 2,
              transformOrigin: "left top"
            }
          }
        }
      }
    }
  }
});

const Contact = () => {
  const [count, setCount] = useState(0);
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    document.title = "Contact - Jake Harris Codes"
  }, []);

  const toast = useToast();
  
  const formik = useFormik({
    initialValues: {
      email: '',
      title: '',
      message: '',
      recaptcha: ''
    },
    validationSchema: Yup.object({
      email: Yup.string().email('Invalid email address').required('Required'),
      title: Yup.string().required('Required').max(50, 'Must be 50 characters or less'),
      message: Yup.string().required('Required').max(1000, 'Must be 1000 characters or less'),
      recaptcha: Yup.string().required('Please complete the reCAPTCHA')
    }),
    onSubmit: async (values) => {
      try {
        // Stop spam with this (they have to do recaptcha again)
        if (submitted) {
          window.location.reload();
        }
        const response = await fetch('https://api.emailjs.com/api/v1.0/email/send', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            service_id: 'service_dc24vg9',
            template_id: 'template_1jfdems',
            user_id: 'VSZOTcqfB36NT3oeq',
            template_params: {
              from_email: values.email,
              title: values.title,
              message: values.message
            }
          })
        });
        if (response.ok) {
          // Reset form after successful submission
          // resetForm(); NO LONGER USED
          // Set submitted state to true and show toast msg
          toast({
            title: 'Message Sent',
            description: "Your contact message has been sent in an email to us.",
            variant: 'left-accent',
            status: 'success',
            position: 'top',
            duration: 15000,
            isClosable: true,
          })
          setSubmitted(true);
        } else {
          console.error('Submission failed:', response.statusText);
          // Handle submission failure, display error message or retry logic
        }
      } catch (error) {
        console.error('Submission error:', error);
        // Handle submission error, display error message or retry logic
      }
    }
  });

  return (
    <ThemeProvider theme={theme}>
      <HeaderBanner headerText="Contact Me" bodyText="Use the form below to contact me. We will respond to you with the email you give us." />

          <form onSubmit={formik.handleSubmit}>
            <Grid templateColumns="1fr" maxW="1100px" mx="auto" mt="30px" p={{ base: "4", lg: "0" }}>

                <FormControl variant="floating" id="email" isRequired maxW="600px" mb="8" isInvalid={formik.errors.email && formik.touched.email}>
                  <Input type='email' placeholder=" " {...formik.getFieldProps('email')} disabled={submitted} />
                  <FormLabel>Email</FormLabel>
                  <FormErrorMessage>{formik.errors.email}</FormErrorMessage>
                </FormControl>

                <FormControl variant="floating" id="title" isRequired maxW="600px" mb="8" isInvalid={formik.errors.title && formik.touched.title}>
                  <Input type='text' placeholder=" " {...formik.getFieldProps('title')} disabled={submitted} />
                  <FormLabel>Title</FormLabel>
                  <FormErrorMessage>{formik.errors.title}</FormErrorMessage>
                </FormControl>

                <FormControl variant="floating" id="message-body" isRequired maxW="600px" mb="8" isInvalid={formik.errors.message && formik.touched.message}>
                  <Textarea placeholder=" " rows="4" onChange={e => {formik.setFieldValue('message', e.target.value); setCount(e.target.value.length)}} value={formik.values.message} disabled={submitted} />
                  <FormLabel>Message ({count} / 1000)</FormLabel>
                  <FormErrorMessage>{formik.errors.message}</FormErrorMessage>
                </FormControl>

              {!submitted && (
                <Box mb="8">
                  <ReCAPTCHA
                    sitekey="6LdzfGsqAAAAAPjpWZolFckcU2Eoh9NQU_HafuLm"
                    theme="dark"
                    onChange={(value) => formik.setFieldValue('recaptcha', value)}
                    onBlur={formik.handleBlur('recaptcha')}
                  />
                  {formik.errors.recaptcha && formik.touched.recaptcha && (
                    <Text color="red.300" mt="2">Please complete the reCAPTCHA!</Text>
                  )}
                </Box>
              )}

              <Button bg="#3182CE" colorScheme="blue" color="white" maxW="80px" type="submit" isLoading={formik.isSubmitting} id="submitBtn" isDisabled={submitted}>
                {!submitted ? "Submit" : <IoIosCheckmark fontSize="42px" /> }
              </Button>
            </Grid>
          </form>
    </ThemeProvider>
  );
};

export default Contact;
