// Our custom 404 page!

import React, { useEffect } from "react";
import { Box, Image, Grid, Heading, Card, CardHeader, CardBody, Text, Button } from '@chakra-ui/react';
import { Link as ReactRouterLink } from 'react-router-dom';
import { FaHome } from "react-icons/fa";
import "../spinnyblob.css"; 

const PageNotFound = () => {

    useEffect(() => {
      document.title = "404 - Jake Harris Codes"
    }, []);

    return (
      <Box mt="16">
        <Grid templateColumns="1fr" maxW="800px" maxH="125px" mx="auto" position="relative">
          <Card bgColor="#0E1219" align="center" textAlign="center" border="1px" borderRadius="lg" borderColor="gray.600" mx="5">
            <CardHeader bgColor="red.600" width="100%" borderTopRadius="lg" textAlign="left">
              <Heading size="md">Error 404 - Not Found</Heading>
            </CardHeader>
            <CardBody>
              <Box>
                <Image boxSize="150px" src="blobcry.png" alt="Blob Crying Emoji" mx="auto" id="spinnyblob" />
                <Heading fontWeight="700" mt="2" color="white">
                  404 Error
                </Heading>
                <Text>
                  The requested page was not found, please check the URL and try again.
                </Text>
                <Button as={ReactRouterLink} to="/" color="blue.500" fontWeight="500" maxW="250px" mt="6" variant="outline">
                  <FaHome /> &nbsp; Return to Home Page
                </Button>
              </Box>
            </CardBody>
          </Card>
        </Grid>
      </Box>
    );
};
 
export default PageNotFound;