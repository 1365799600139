import React from 'react';
import ReactDOM from 'react-dom/client';
import { ChakraProvider, CSSReset, ColorModeScript, extendTheme } from '@chakra-ui/react';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

// Explicitly set the color mode
const preferredColorMode = 'dark'; // Change to 'light' if you prefer light mode

localStorage.setItem('chakra-ui-color-mode', preferredColorMode);

const theme = extendTheme({
  config: {
    initialColorMode: preferredColorMode,
    useSystemColorMode: false,
  },
});

const root = document.getElementById('root');
ReactDOM.createRoot(root).render(
  <ChakraProvider theme={theme}>
    <ColorModeScript initialColorMode={preferredColorMode} />
    <CSSReset />
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </ChakraProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();