// src/Components/HeaderBanner.js
import React from 'react';
import { Box, Text, Flex, Grid, Heading } from '@chakra-ui/react';

const HeaderBanner = ({ headerText, bodyText }) => {
  return (
    <Box bg="#0E1219" p="2vh" w="100%" zIndex="5" h="125px">
      <Grid templateColumns="1fr" maxW="1100px" maxH="125px" mx="auto" position="relative">
        <Flex flexDirection="column" justifyContent="center">
          <Box>
            <Heading bgGradient="linear(to-r, purple.500, blue.500)" bgClip="text" textAlign="left" display="inline">
              {headerText}
            </Heading>
            <Text fontWeight="500" mt="2">
              {bodyText}
            </Text>
          </Box>
        </Flex>
      </Grid>
    </Box>
  );
};

export default HeaderBanner;
