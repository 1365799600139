import React from 'react';
import { Box } from '@chakra-ui/react';

const WaveSeparator = () => {
  return (
    <Box className="jakes-divider" overflow="hidden" position="static">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 35.28 2.17"
        preserveAspectRatio="xMidYMin slice"
        fill="#0e1219"
        style={{
          position: 'absolute',
          zIndex: 3,
          pointerEvents: 'none',
          bottom: '-0.1vw',
          left: '-0.1vw',
          right: '-0.1vw',
          top: '0vw',
          width: '100%',
          height: '100%',
        }}
      >
        <path d="M35.28 1.67c-3.07-.55-9.27.41-16.15 0-6.87-.4-13.74-.58-19.13.1v.4h35.28z" />
      </svg>
    </Box>
  );
};

export default WaveSeparator;
