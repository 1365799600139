import React, { useEffect, useState } from "react";
import { Box, Heading, Text, HStack, VStack, Flex } from "@chakra-ui/react";
import { FaArrowUp, FaArrowDown, FaLaravel, FaReact, FaBootstrap, FaNodeJs } from "react-icons/fa";
import { DiDotnet } from "react-icons/di";
import { SiChakraui } from "react-icons/si";
import AOS from 'aos';
import 'aos/dist/aos.css';
import SkillItem from '../components/SkillItem';
import ReactDOMServer from "react-dom/server";

const AboutMeSection = () => {
  const [divOpacity, setDivOpacity] = useState(0.5);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const windowHeight = window.innerHeight;
      const tenPercentOfViewport = 0.1 * windowHeight;
      const element = document.getElementById("section2");

      if (element) {
        const elementPosition = element.offsetTop;
        const fadeStart = elementPosition - windowHeight + 200; // was 170
        const relativePosition = scrollPosition - fadeStart;

        const newOpacity = Math.min(Math.max(relativePosition / 400, 0.5), 1);

        setDivOpacity(newOpacity);

        const arrowButton = document.getElementById("arrowButton");
        const arrowUpHtml = ReactDOMServer.renderToString(<FaArrowUp fontSize="1.2rem" />);
        const arrowDownHtml = ReactDOMServer.renderToString(<FaArrowDown fontSize="1.2rem" />);

        if (scrollPosition >= tenPercentOfViewport) {
          if (arrowButton && arrowButton.innerHTML !== arrowUpHtml) {
            arrowButton.innerHTML = arrowUpHtml;
          }
        } else {
          if (arrowButton && arrowButton.innerHTML !== arrowDownHtml) {
            arrowButton.innerHTML = arrowDownHtml;
          }
        }
      }
    };

    handleScroll(); // Call once to set initial state
    AOS.init({
      offset: 100,
      duration: 600,
      easing: 'ease-in-sine',
      delay: 100,
    });
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [divOpacity]);

  return (
    <><HStack
      spacing="4"
      position="absolute"
      bottom="-55"
      left="50%"
      transform="translateX(-50%)"
      zIndex="5"
      width="100%"
      maxW="1100px"
      alignItems="center"
      justifyContent="space-between"
      id="section2"
      className="fadein3secshalf"
      opacity={divOpacity}
      direction={{ base: "column", md: "row" }}
      display={{ base: "none", md: "flex" }}
    >
      {/* Other Column (to the left) */}
      <Box
        maxW={{ base: "100%", md: "calc(100% - 600px)" }}
        position="absolute"
        bottom="-220"
        borderRadius="10px"
        order={{ base: 2, md: 1 }} // Change the order for responsive layout
        mb={{ base: "20px", md: 0 }} // Add margin at the bottom for better separation on smaller screens
      >
        <svg width="384" height="384" viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg" style={{ borderRadius: "10px" }}>
          <rect width="100%" height="100%" rx="10" fill="#000000" />
          <image
            href="/jake.jpg"
            width="100%"
            height="100%"
            clipPath="url(#squareClip)"
            preserveAspectRatio="xMidYMid slice"
          />
          <defs>
            <clipPath id="squareClip">
              <rect width="100%" height="100%" rx="10" />
            </clipPath>
          </defs>
        </svg>
      </Box>

      {/* About Me */}
      <Box position="relative" textAlign="left" maxW={{ base: "100%", md: "600px" }} height="100%" ml="auto" order={{ base: 1, md: 2 }}>
        <Heading fontSize={{ base: "3xl", md: "5xl" }} position="absolute" top="0" left="0" px={{ base: "4", md: "0" }}>
          About Me
        </Heading>
        {/* 1st Paragraph */}
        <Text
          fontSize={{ base: "md", md: "lg" }}
          textAlign="left"
          fontWeight="400"
          mt={{ base: "50px", md: "70px" }}
          px={{ base: "4", md: "0" }}
          transition="opacity 0.75s ease"
        >
          Hello, my name is Jake Harris and I am based in Sydney, Australia. I grew up with computers and have loved them ever since. I am currently in university studying a Bachelor of IT in order to pursue my career in IT. At the moment I call myself a Junior Web Developer, and have used university and my own research to further improve my knowledge with web technologies.
        </Text>
        {/* Additional Text 1 */}
        <Text
          fontSize={{ base: "md", md: "lg" }}
          textAlign="left"
          fontWeight="400"
          position="absolute"
          mt={{ base: "15px", md: "25px" }}
          px={{ base: "4", md: "0" }}
          transition="opacity 0.75s ease"
          data-aos="fade-up"
        >
          I have a lot of experience with computer programming. I've built many websites and apps with many different programming languages and frameworks. My strongest web frameworks are ASP.NET EF Core and Laravel. I enjoy using React.js too, and this website uses it!
        </Text>
        {/* Additional Text 2 */}
        <Text
          fontSize={{ base: "md", md: "lg" }}
          textAlign="left"
          fontWeight="400"
          position="absolute"
          mt={{ base: "150px", md: "160px" }}
          px={{ base: "4", md: "0" }}
          transition="opacity 0.75s ease"
          data-aos="fade-up"
        >
          I'm very enthusiastic when it comes to computer programming. I'm always willing to explore new programming languages or frameworks. Learning is extremely fun, and sometimes tedious!
        </Text>
      </Box>

      {/* Skills Section */}
      <Box
        position="absolute"
        bottom="-575"
        borderRadius="10px"
        width="100%"
        textAlign="center"
        left="50%"
        transform="translateX(-50%)"
        mb={{ base: "20px", md: 0 }} // Add margin at the bottom for better separation on smaller screens
      >
        <Heading fontSize={{ base: "3xl", md: "5xl" }} px={{ base: "4", md: "0" }} data-aos="fade-up">
          Skills
        </Heading>
        <Flex justify="center" align="center" gap="6" mt="8" data-aos="fade-up">
          <SkillItem icon={<FaLaravel />} skillLink="https://laravel.com/" skillName="laravel" />
          <SkillItem icon={<FaReact />} skillLink="https://react.dev/" skillName="react.js" />
        </Flex>
        <Flex justify="center" align="center" gap="6" mt="4" data-aos="fade-up">
          <SkillItem icon={<FaBootstrap />} skillLink="https://getbootstrap.com/" skillName="bootstrap" />
          <SkillItem icon={<FaNodeJs />} skillLink="https://nodejs.org/en" skillName="node.js" />
        </Flex>
        <Flex justify="center" align="center" gap="6" mt="4" data-aos="fade-up">
          <SkillItem icon={<DiDotnet />} skillLink="https://asp.net/" skillName="asp.net" />
          <SkillItem icon={<SiChakraui />} skillLink="https://chakra-ui.com/" skillName="chakra ui" />
        </Flex>
      </Box>
    </HStack>

    {/* VStack for smaller viewports */}
    <VStack
      spacing="4"
      position="absolute"
      bottom="-90"
      left="50%"
      transform="translateX(-50%)"
      zIndex="5"
      width="100%"
      maxW="1100px"
      alignItems="center"
      id="section2"
      className="fadein3secs"
      display={{ base: "flex", md: "none" }}
    >
        {/* About Me Column */}
        <Box position="relative" textAlign="left" maxW="100%" height="100%">
          <Heading fontSize={{ base: "3xl", md: "5xl" }} position="absolute" top="0" left="0" px={{ base: "4", md: "0" }}>
            About Me
          </Heading>
          {/* 1st Paragraph */}
          <Text
            fontSize={{ base: "md", md: "lg" }}
            textAlign="left"
            fontWeight="400"
            mt={{ base: "50px", md: "70px" }}
            px={{ base: "4", md: "0" }}
            transition="opacity 0.75s ease"
          >
            Hello, my name is Jake Harris and I am based in Sydney, Australia. I grew up with computers and have loved them ever since. I am currently in university studying a Bachelor of IT in order to pursue my career in IT. At the moment I call myself a Junior Web Developer, and have used university and my own research to further improve my knowledge with web technologies.
          </Text>
          {/* Additional Text 1 */}
          <Text
            fontSize={{ base: "md", md: "lg" }}
            textAlign="left"
            fontWeight="400"
            position="absolute"
            mt={{ base: "15px", md: "25px" }}
            px={{ base: "4", md: "0" }}
            transition="opacity 0.75s ease"
          >
            I have a lot of experience with computer programming. I've built many websites and apps with many different programming languages and frameworks. My strongest web frameworks are ASP.NET EF Core and Laravel. I enjoy using React.js too, and this website uses it!
          </Text>
          {/* Additional Text 2 */}
          <Text
            fontSize={{ base: "md", md: "lg" }}
            textAlign="left"
            fontWeight="400"
            position="absolute"
            mt={{ base: "170px", md: "160px" }}
            px={{ base: "4", md: "0" }}
            transition="opacity 0.75s ease"
          >
            I'm very enthusiastic when it comes to computer programming. I'm always willing to explore new programming languages or frameworks. Learning is extremely fun, and sometimes tedious!
          </Text>
                {/* Skills Section */}
      <Box
        position="absolute"
        bottom="-575"
        borderRadius="10px"
        width="100%"
        textAlign="center"
        left="50%"
        transform="translateX(-50%)"
        mb={{ base: "20px", md: 0 }} // Add margin at the bottom for better separation on smaller screens
      >
        <Heading fontSize={{ base: "3xl", md: "5xl" }} px={{ base: "4", md: "0" }}>
          Skills
        </Heading>
        <Flex justify="center" align="center" gap="6" mt="8">
          <SkillItem icon={<FaLaravel />} skillLink="https://laravel.com/" skillName="laravel" />
          <SkillItem icon={<FaReact />} skillLink="https://react.dev/" skillName="react.js" />
        </Flex>
        <Flex justify="center" align="center" gap="6" mt="4">
          <SkillItem icon={<FaBootstrap />} skillLink="https://getbootstrap.com/" skillName="bootstrap" />
          <SkillItem icon={<FaNodeJs />} skillLink="https://nodejs.org/en" skillName="node.js" />
        </Flex>
        <Flex justify="center" align="center" gap="6" mt="4">
          <SkillItem icon={<DiDotnet />} skillLink="https://asp.net/" skillName="asp.net" />
          <SkillItem icon={<SiChakraui />} skillLink="https://chakra-ui.com/" skillName="chakra ui" />
        </Flex>
      </Box>
        </Box>
      </VStack>
      </>
  );
};

export default AboutMeSection;
