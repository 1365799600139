// src/Components/Footer.js
// Pretty much only used on index page
import React from 'react';
import { Box, Text, HStack, VStack, Flex, Grid } from '@chakra-ui/react';
import { FaHeart } from "react-icons/fa";

const Footer = () => {
  return (
    <Box
      bg="linear-gradient(90deg, #030406 0%, #111213 100%)"
      p="1"
      w="100%"
      h={{ base: 'auto', md: '70px' }} // Adjust height for different screen sizes
      position="absolute"
      bottom="-1000"
      zIndex="7"
    >
      <Grid templateColumns="1fr" maxW="1100px" mx="auto">
        <Flex alignItems="center">
          <VStack spacing={{ base: '2', md: '1' }} align="start" gap="unset">
            <HStack fontSize={{ base: 'lg', md: '2xl' }} fontWeight="700" color="white" flexWrap="wrap">
              <Text>Made with</Text>
              <Box mx="1">
                <FaHeart color="red" />
              </Box>
              <Text>in Sydney</Text>
            </HStack>

            <Text fontSize={{ base: 'xs', md: 'sm' }} color="gray.500">
              &copy; {new Date().getFullYear()} Jake Harris. All rights reserved.
            </Text>
          </VStack>
        </Flex>
      </Grid>
    </Box>
  );
};

export default Footer;
