// components/SkillItem.js
import React from "react";
import { Box, HStack, Text, Link } from "@chakra-ui/react";

const SkillItem = ({ icon, skillLink, skillName }) => {
  return (
    <Box bgColor="#11151D" width="30%" borderRadius="lg" p="1" borderBottom="2px solid #fff" borderBottomRadius="2px" textTransform="uppercase" fontWeight="500" cursor="default" id="skillBox">
    <HStack alignItems="center" justifyContent="center">
      <Link id="skillBoxText" display="flex" gap="2" alignItems="center" textDecoration="none !important" href={skillLink} isExternal>
        {React.cloneElement(icon)}
        <Text>{skillName}</Text>
      </Link>
    </HStack>
  </Box>
  );
};

export default SkillItem;
