import React, { useEffect, useState, useRef } from "react";
import Particles from "@tsparticles/react";
import { initParticlesEngine } from "@tsparticles/react";
import { loadSlim } from "@tsparticles/slim";
import {
  IconButton,
  Box,
  HStack,
  Grid,
  Heading,
  Text,
  Flex,
  Image
} from "@chakra-ui/react";
import { animateScroll } from "react-scroll";
import { TypeAnimation } from "react-type-animation";
import { FaArrowUp, FaArrowDown, FaLinkedin, FaYoutube } from "react-icons/fa";
import WaveSeparator from "../components/WaveSeparator";
import SocialIcon from "../components/SocialIcon";
import AboutMeSection from "../components/AboutMeSection";
import Footer from "../components/Footer";
import "../particles-fix.css";
import "../fadeineffect.css";

const Index = () => {
  const isTopRef = useRef(true);
  const [particlesInit, setParticlesInit] = useState(false);
  
  const scrollUpOptions = {
    duration: 1000,
    smooth: true,
  };

  const scrollDownOptions = {
    duration: 2000,
    smooth: true,
  };

  useEffect(() => {
    document.title = "Home - Jake Harris Codes";

    initParticlesEngine(async (engine) => {
      await loadSlim(engine);
    }).then(() => {
      setParticlesInit(true);
    });
  }, []);

  const handleScroll = () => {
    const scrollPosition = window.scrollY;
    const atTop = scrollPosition === 0;

    // Update isTop without triggering re-render
    isTopRef.current = atTop;
  };

  useEffect(() => {
    handleScroll();
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScrollButtonClick = () => {
    if (isTopRef.current) {
      animateScroll.scrollTo(725, scrollDownOptions);
    } else {
      animateScroll.scrollToTop(scrollUpOptions);
    }
  };

  const scrollButtonIcon = isTopRef.current ? (
    <FaArrowDown fontSize="1.2rem" />
  ) : (
    <FaArrowUp
      fontSize="1.2rem"
    />
  );

  const particlesOptions = {
    background: {
      color: {
        value: "#11151d",
      },
    },
    fullScreen: false,
    fpsLimit: 60,
    interactivity: {
      detectsOn: "canvas",
      events: {
        resize: true,
      },
      modes: {
        push: {
          quantity: 4,
        },
        repulse: {
          distance: 200,
          duration: 0.4,
        },
      },
    },
    particles: {
      color: {
        value: "#ffffff",
      },
      links: {
        color: "#ffffff",
        distance: 150,
        enable: true,
        opacity: 0.4,
        width: 1,
      },
      collisions: {
        enable: true,
      },
      move: {
        direction: "none",
        enable: true,
        outMode: "out",
        random: false,
        speed: 6,
        straight: false,
      },
      number: {
        density: {
          enable: true,
          value_area: 800,
        },
        value: 80,
      },
      opacity: {
        value: 0.5,
      },
      shape: {
        type: "circle",
      },
      size: {
        random: true,
        value: 3,
      },
    },
    detectRetina: true,
  };

  return (
    <>
      <Box
        position="absolute"
        bottom="0"
        left="0"
        width="100%"
        height="10%"
        background="transparent"
        filter="auto"
        blur="2px"
        zIndex="99"
      />
    <Box px="4" mt="120px" >
      {/* New Box to manually position content on top of the wave */}
      <Box
        position="absolute"
        bottom="25vh"
        left="50%"
        transform="translateX(-50%)"
        zIndex="4"
        textAlign="center"
        maxW="1100px"
      >
        {/* Content to be placed on top of the wave */}
          <IconButton
            bg="#030406"
            color="#ffffff"
            borderRadius="full"
            icon={scrollButtonIcon}
            onClick={handleScrollButtonClick}
            id="arrowButton"
            />

      </Box>
      {particlesInit && (
          <Particles
            id="cooleffectthing"
            options={particlesOptions}
          />
        )}

      <Grid
        templateColumns={{ base: "1fr", md: "2fr 1fr" }}
        gap={{ base: "4", md: "8" }}
        maxW="1100px"
        mx="auto"
        position="relative"
        id="section1"
      >
        {/* Left Column */}
        <Box mt="0">
          {/* I'm Jake */}
          <Box mb="4" display="flex" alignItems="center" id="fadein1">
            <Heading
              as="h1"
              fontSize={{ base: "5xl", md: "7xl" }}
              textAlign="left"
              color="white"
              mr="4"
              display="inline"
            >
              I'm
            </Heading>

            <Heading
              as="h1"
              fontSize={{ base: "5xl", md: "7xl" }}
              textAlign="left"
              bgGradient="linear(to-r, purple.500, blue.500)"
              bgClip="text"
              display="inline"
            >
              Jake
            </Heading>
          </Box>

          {/* Dynamic Bio Text */}
          <Text
            fontSize={{ base: "md", md: "lg" }}
            textAlign="left"
            mb="4"
            fontWeight="500"
            id="fadein2"
          >
            A student with a passion for{" "}
            <TypeAnimation
              sequence={[
                "website development.",
                3500,
                "learning.",
                3500,
                "educating others.",
                3500,
                "retro gaming.",
                3500,
                "video making.",
                3500,
              ]}
              wrapper="span"
              speed={35}
              style={{ fontSize: "lg" }}
              repeat={Infinity} />
          </Text>

          {/* Socials */}
          <HStack spacing="4" mt="100px" id="fadein3">
            <Box id="fadein4">
              <SocialIcon
                profileLink="https://www.linkedin.com/in/jake-harris-codes/"
                icon={<FaLinkedin />}
                col="#0A66C2" />
            </Box>
            <Box id="fadein5">
              <SocialIcon
                profileLink="https://www.youtube.com/channel/UChnmpMyV8-3G6v7myb-EFaQ"
                icon={<FaYoutube />}
                col="#FE0000" />
            </Box>
          </HStack>

        </Box>
        {/* Right Column */}
          {/* Image goes here */}
          <Image src="/jake_pic.png" alt="Jake Harris" id="photo-fadein" transform="scale(1.2)" paddingBottom="200px" display={{ base: "none", md: "block" }} />
      </Grid>
      {/* Slope Divider */}
      <WaveSeparator />
      <AboutMeSection />
      <Box bg="#11151D" p="2vh" w="100%" zIndex="5" position="absolute" bottom="-850" left="0" data-aos="fade">
        <Grid templateColumns="1fr" maxW="1100px" maxH="125px" mx="auto" position="relative">
          <Flex flexDirection="row" justifyContent="center" textAlign="center">
            <Box>
              <Heading fontWeight="300" fontStyle="italic" fontSize="2xl">
              “Web development is the art of digital creation.”
              </Heading>
            </Box>
          </Flex>
        </Grid>
      </Box>

      <Box
        position="absolute"
        bottom="-1000"
        left="0"
        width="100%"
        height="175vh"
        backgroundColor="#0E1219"
        zIndex="-1" />
    </Box>
    {/* Footer */}
    <Footer />
  </>
  );
};

export default Index;
