// Layout.js
import React from 'react';
import { Box } from '@chakra-ui/react';
import Navbar from './components/Navbar';

const Layout = ({ children }) => {
  return (
    <>
        <Box color="white" bg="#11151d" minHeight="100vh">
        <Navbar />
            {children}
        </Box>
    </>
  );
};

export default Layout;
